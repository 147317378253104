<template>
  <a-modal
    :visible="show"
    @cancel="$emit('close')">
    <h3 slot="title">
      <a-icon class="mr-2" type="cloud-download" />
      {{$t('msg.shipment.export.title')}}
    </h3>
    <!-- Form -->
    <div class="mb-2">
      {{ $t('msg.shipment.export.subtitle1') }}
    </div>
    <!-- Count -->
    <h5 class="text-lowercase">
      {{ results.items | number }} {{ $t('shipments')}}.
      <!-- Warning -->
      <a-tooltip
        placement="right"
        :title="$t('msg.shipment.export.warning', { limit })"
        v-if="blocked">
        <a-icon
          type="exclamation-circle"
          class="text-danger font-size-14 ml-1" />
      </a-tooltip>
    </h5>
    <!-- Email -->
    <div class="mt-3 mb-2">
      {{ $t('msg.shipment.export.subtitle2') }}
    </div>
    <a-input
      v-model="email"
      size="large"
      read-only />
    <div class="mt-3">
      {{ $t('msg.shipment.print.subtitle3') }}
    </div>
    <!-- Actions -->
    <div slot="footer" class="p-3">
      <a-button
        key="back"
        @click="onCancel">
        {{$t('cancel')}}
      </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        :disabled="blocked"
        @click="onConfirm">
        {{$t('export')}}
      </a-button>
    </div>
  </a-modal>
</template>
<script>
import lms from '@/api/lms'
import { flatJsonFields } from '@/utils/requests'
export default {
  props: ['show'],
  data() {
    return {
      limit: 10000,
      loading: false,
    }
  },
  computed: {
    visible() {
      return this.show
    },
    email() {
      return this.$store.getters['session/USER'].email
    },
    results() {
      return this.$store.getters['shipments/RESULTS']
    },
    filters() {
      return this.$store.getters['shipments/FILTERS']
    },
    blocked() {
      return this.results.items === 0 || this.results.items > this.limit
    },
  },
  methods: {
    onConfirm() {
      this.loading = true
      const filters = flatJsonFields(this.filters)
      const request = {
        type: 'SUMMARY_SHIPMENTS',
        email: this.email,
        filter: filters,
      }
      lms.exports.start(request)
        .then(_ => this.$router.push('/shipments/exported'))
        .finally(() => (this.loading = false))
    },
    onCancel() {
      this.$emit('close')
    },
  },
}
</script>
