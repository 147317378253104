<template>
  <a-drawer
      :title="$t('filter')"
      placement="right"
      :visible="show"
      :width="350"
      :after-visible-change="onVisibilityChange"
      @close="$emit('close')">

    <h3 slot="title">
      {{$t('filter')}}
    </h3>

    <!-- Form -->

    <a-form-model :model="filters">

      <!-- Sort -->
      <a-form-model-item>
        <a-select
          v-model="filters.sort"
          size="large">
          <a-select-option value="NEWEST">
            {{$t('enum.sort.NEWEST')}}
          </a-select-option>
          <a-select-option value="OLDEST">
            {{$t('enum.sort.OLDEST')}}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <!-- Status -->
      <a-form-model-item>
        <a-select
          mode="multiple"
          option-filter-prop="children"
          v-model="filters.status"
          :placeholder="$t('status')">
          <a-select-option
            v-for="status in statuses"
            :key="status">
            {{ $t(`enum.status.${status}`) }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <!-- SubStatus -->
      <a-form-model-item>
        <a-select
          mode="multiple"
          option-filter-prop="children"
          v-model="filters.substatus"
          :placeholder="$t('substatus')">
          <a-select-option
            v-for="substatus in substatuses"
            :key="substatus">
            {{ $t(`enum.status.${substatus}`) }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <!-- Shortcut -->
      <a-form-model-item>
        <a-radio-group
          v-model="filters.shortcut">
          <a-radio
            :style="radioStyle"
            value="PENDING">
            {{ $t('msg.shipment.filters.pending') }}
          </a-radio>
          <a-radio
            :style="radioStyle"
            value="ACTIVE">
            {{ $t('msg.shipment.filters.active') }}
          </a-radio>
          <a-radio
            :style="radioStyle"
            value="DELAYED">
            {{ $t('msg.shipment.filters.delayed') }}
          </a-radio>
          <a-radio
            :style="radioStyle"
            value="BLOCKED">
            {{ $t('msg.shipment.filters.blocked') }}
          </a-radio>
          <a-radio
            :style="radioStyle"
            value="FLEX_NOT_MARKED"
            v-if="$auth.granted(acl.shipments.monitor)">
            {{ $t('msg.shipment.filters.notMarked') }}
          </a-radio>
        </a-radio-group>
      </a-form-model-item>

      <!-- Method -->
      <a-form-model-item>
        <a-select
          mode="multiple"
          v-model="filters.method"
          :placeholder="$t('method')">
          <a-select-option value="EXPRESS">
            {{$t('enum.method.EXPRESS')}}
          </a-select-option>
          <a-select-option value="STANDARD">
            {{$t('enum.method.STANDARD')}}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <!-- Platform -->
      <a-form-model-item>
        <a-select
          mode="multiple"
          option-filter-prop="children"
          v-model="filters.platform"
          :placeholder="$t('platform')">
          <a-select-option
            v-for="platform in platforms"
            :key="platform">
            {{ $t(`enum.source.${platform}`) }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <!-- Account -->
      <a-form-model-item
        v-if="$auth.granted(acl.shipments.monitor)">
        <a-select
          mode="multiple"
          v-model="filters.account"
          :filter-option="false"
          :not-found-content="null"
          :placeholder="$t('account')"
          @search="onSearchAccount">
          <a-select-option
            v-for="account in accounts"
            :key="account.key">
            {{ account.value }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <!-- Courier -->
      <a-form-model-item
        v-if="$auth.granted(acl.shipments.monitor)">
         <a-select
          mode="multiple"
          v-model="filters.courier"
          :filter-option="false"
          :not-found-content="null"
          :placeholder="$t('courier')"
          @search="onSearchCourier">
          <a-select-option
            v-for="courier in couriers"
            :key="courier.key">
            {{ courier.value }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <!-- Route -->
      <a-form-model-item
        v-if="$auth.granted(acl.shipments.monitor)">
        <a-select
          mode="tags"
          v-model="filters.route"
          :not-found-content="null"
          :placeholder="$t('route')">
        </a-select>
      </a-form-model-item>

      <!-- Date -->
      <a-form-model-item>
        <a-select
          v-model="filters.date.type"
          size="large">
          <a-select-option value="CREATED">
            {{$t('enum.dateType.CREATED')}}
          </a-select-option>
          <a-select-option value="UPDATED">
            {{$t('enum.dateType.UPDATED')}}
          </a-select-option>
          <a-select-option value="PROMOTED">
            {{$t('enum.dateType.PROMOTED')}}
          </a-select-option>
          <a-select-option value="DISPATCHED">
            {{$t('enum.dateType.DISPATCHED')}}
          </a-select-option>
        </a-select>
        <j-range-picker
          v-model="filters.date.range" />
      </a-form-model-item>

      <a-divider />

      <!-- Actions -->
      <a-form-model-item>
        <a-button
          key="submit"
          class="mr-2"
          type="primary"
          @click="onApply">
          {{$t('apply')}}
        </a-button>
        <a-button
          key="back"
          type="link"
          @click="onClear">
          {{$t('clear')}}
        </a-button>
      </a-form-model-item>

    </a-form-model>

  </a-drawer>
</template>
<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import { DefaultFilters } from '@/store/shipments'
import { status } from '@/utils/styles'
import scheduler from '@/utils/scheduler'
import JRangePicker from '@/views/shared/base/range-picker'

const suggester = scheduler(p => lms.suggestion.search(p.query, p.type))

export default {
  props: ['show'],
  components: {
    JRangePicker,
  },
  data() {
    return {
      acl,
      filters: { ...DefaultFilters() },
      accounts: [],
      couriers: [],
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
    }
  },
  computed: {
    visible() {
      return this.show
    },
    statuses() {
      return Object.keys(status).filter(key => status[key].level === 0)
    },
    substatuses() {
      return Object.keys(status).filter(key => status[key].level === 1)
    },
    platforms() {
      return ['VTX', 'TNB', 'WOO', 'MLI', 'JPK', 'P2P']
    },
  },
  methods: {
    onVisibilityChange() {
      this.filters = { ...this.$store.getters['shipments/FILTERS'] }
    },
    onSearchAccount(query) {
      suggester.schedule({ query, type: 'ACCOUNT' }).then(r => (this.accounts = r.data))
    },
    onSearchCourier(query) {
      suggester.schedule({ query, type: 'COURIER' }).then(r => (this.couriers = r.data))
    },
    onApply() {
      this.$store.commit('shipments/SET_FILTERS', { ...this.filters })
      this.$emit('close')
    },
    onClear() {
      this.$store.commit('shipments/CLEAR_FILTERS')
      this.$emit('close')
    },
  },
}
</script>
