<template>
  <a-dropdown :trigger="['click']">
    <a class="ant-dropdown-link">
      <a-icon type="calendar" class="mr-1" />
      <template v-if="current.range.min && current.range.max">
        {{ current.range.min | date }} {{ $t('to') }} {{ current.range.max | date }}
      </template>
      <template v-else>
      {{ $t(`enum.dateKey.${current.key}`) }}
      </template>
      <a-icon type="down" />
    </a>
    <a-menu slot="overlay" @click="onChange">
      <a-menu-item key="LAST_DAY">
        {{ $t('enum.dateKey.LAST_DAY') }}
      </a-menu-item>
      <a-menu-item key="LAST_WEEK">
        {{ $t('enum.dateKey.LAST_WEEK') }}
      </a-menu-item>
      <a-menu-item key="LAST_MONTH">
        {{ $t('enum.dateKey.LAST_MONTH') }}
      </a-menu-item>
      <a-menu-item key="LAST_SIX_MONTHS">
        {{ $t('enum.dateKey.LAST_SIX_MONTHS') }}
      </a-menu-item>
      <a-menu-item key="LAST_YEAR">
        {{ $t('enum.dateKey.LAST_YEAR') }}
      </a-menu-item>
    </a-menu>
  </a-dropdown>
</template>
<script>
export default {
  data: function () {
    return {}
  },
  computed: {
    current() {
      return this.$store.getters['shipments/FILTERS'].date
    },
  },
  methods: {
    onChange(value) {
      const date = {
        type: 'CREATED',
        key: value.key,
        range: {
          min: undefined,
          max: undefined,
        },
      }
      this.$store.commit('shipments/SET_FILTERS', { date })
    },
  },
}
</script>
