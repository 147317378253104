<template>
  <a-range-picker
    v-model="date"
    format="DD/MM/YYYY" />
</template>

<script>
export default {
  name: 'Input',
  props: [
    'value',
  ],
  computed: {
    date: {
      get() {
        if (this.value.min && this.value.max) {
          return [
            this.value.min,
            this.value.max,
          ]
        } else {
          return []
        }
      },
      set(value) {
        let min
        let max
        if (value.length === 2) {
          min = value[0].startOf('day')
          max = value[1].endOf('day')
        }
        this.$emit('input', { min, max })
      },
    },
  },
}
</script>
