<template>
  <a-modal
    :visible="show"
    @cancel="$emit('close')">
    <h3 slot="title">
      <a-icon class="mr-2" type="printer" />
      {{$t('msg.shipment.print.title')}}
    </h3>
    <!-- Form -->
    <div class="mb-2">
      {{ $t('msg.shipment.print.subtitle1') }}
    </div>
    <!-- Count -->
    <h5 class="text-lowercase">
      {{ results.items | number }} {{ $t('labels')}}.
      <!-- Warning -->
      <a-tooltip
        placement="right"
        :title="$t('msg.shipment.print.warning', { limit })"
        v-if="blocked">
        <a-icon
          type="exclamation-circle"
          class="text-danger font-size-14 ml-1" />
      </a-tooltip>
    </h5>
    <!-- Printer -->
    <div class="mt-3 mb-2">
      {{ $t('msg.shipment.print.subtitle2') }}
    </div>
    <a-select v-model="printer" size="large">
      <a-select-option
        v-for="key in printers"
        :key="key" :value="key">
        {{ $t(`enum.printer.${key}`) }}
      </a-select-option>
    </a-select>
    <div class="mt-3">
      {{ $t('msg.shipment.print.subtitle3') }}
    </div>
    <!-- Actions -->
    <div slot="footer" class="p-3">
      <a-button
        key="back"
        @click="onCancel">
        {{$t('cancel')}}
      </a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        :disabled="blocked"
        @click="onConfirm">
        {{$t('print')}}
      </a-button>
    </div>
  </a-modal>
</template>
<script>
import lms from '@/api/lms'
export default {
  props: ['show'],
  data() {
    return {
      limit: 500,
      loading: false,
    }
  },
  computed: {
    visible() {
      return this.show
    },
    results() {
      return this.$store.getters['shipments/RESULTS']
    },
    filters() {
      return this.$store.getters['shipments/FILTERS']
    },
    blocked() {
      return this.results.items === 0 || this.results.items > this.limit
    },
    printers() {
      return ['PDF_A4', 'PDF_A7', 'ZPL']
    },
    printer: {
      get () {
        return this.$store.state.settings.printer
      },
      set (printer) {
        this.$store.commit('SET_PRINTER', { printer })
      },
    },
  },
  methods: {
    onConfirm() {
      this.loading = true
      lms.label.download(this.filters, this.printer)
        .then(response => {
          window.open(response.data.url, '_blank')
          this.$emit('close')
        })
        .finally(() => (this.loading = false))
    },
    onCancel() {
      this.$emit('close')
    },
  },
}
</script>
