<template>
  <div>
    <div class="container">
      <div class="row mb-4">
        <!-- Search Bar -->
        <div class="col-12 col-md-6">
          <a-input-search
            v-model="query"
            :placeholder="$t('msg.shipment.search')"
            :allowClear="true"
            @search="onSearch"
            size="large">
            <a-badge :dot="isFiltered"
              slot="addonBefore">
              <a-icon
                type="filter"
                class="font-size-16"
                @click="filtering = true" />
            </a-badge>
          </a-input-search>
        </div>

        <!-- Actions -->

        <div class="col-md-6 d-none d-md-block text-right">
          <a-dropdown-button
            type="primary"
            size="large"
            :trigger="['click']"
            @click="onCreate"
            v-if="$auth.granted(acl.shipments.create)">
            {{ $t('msg.shipment.create.action') }}
            <a-menu slot="overlay">
              <a-menu-item @click="printing = true">
                <a-icon type="printer" class="text-secondary" />
                {{ $t('msg.shipment.print.title') }}
              </a-menu-item>
              <a-menu-item @click="exporting = true">
                <a-icon type="cloud-download" class="text-secondary" />
                {{ $t('msg.shipment.export.title') }}
              </a-menu-item>
            </a-menu>
            <a-icon slot="icon" type="down" />
          </a-dropdown-button>
        </div>
      </div>
    </div>
    <div class="card">

      <!-- Header with Selection -->

      <div class="card-header py-3 font-size-14" v-if="selections > 0">
        <div class="d-flex align-items-center">
          <div class="mr-auto"/>
          <!-- Counter -->
          <a-dropdown :trigger="['click']">
            <a class="ant-dropdown-link text-primary">
              {{ selections }} {{ $t('msg.shipment.selected.title') | lower }}
              <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item @click="onPrint">
                <a-icon type="printer" class="text-secondary" />
                {{ $t('msg.shipment.selected.print') }}
              </a-menu-item>
              <a-menu-item @click="onClear">
                <a-icon type="minus-circle" class="text-secondary" />
                {{ $t('msg.shipment.selected.clear') }}
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-divider type="vertical" class="mx-3" />
          <!-- Refresh -->
          <a-button
            shape="circle"
            icon="sync"
            @click="fetch" />
        </div>
      </div>

      <!-- Header without Selection -->

      <div class="card-header py-3 font-size-14 text-secondary" v-else>
        <div class="d-flex align-items-center">
          <div class="mr-auto">
          </div>
          <!-- Counter -->
          <div>
            {{ results.items | number }} {{ $t('results') | lower }}
          </div>
          <!-- Filtered -->
          <a-tag
            v-if="isFiltered"
            class="ml-3 d-none d-md-block text-lowercase"
            @close="onClearFilters"
            closable>
            {{ $t('filtered') }}
          </a-tag>
          <a-divider type="vertical" class="mx-3" />
          <!-- Period -->
          <j-period />
          <a-divider type="vertical" class="mx-3" />
          <!-- Refresh -->
          <a-button
            shape="circle"
            icon="sync"
            @click="fetch" />
        </div>
      </div>

      <div class="card-body p-0">

        <a-list
          :data-source="results.data"
          :pagination="{
            onChange,
            current: pagination.page,
            defaultPageSize: pagination.size,
            total: results.items,
            simple: true }">

          <!-- Shipment -->

          <a-list-item slot="renderItem" slot-scope="shipment">
            <j-shipment
              :shipment="shipment"
              :checkable="true"
              :checked="selected[shipment.code]"
              @check="onCheck">
              <template #cta>
                <j-detail-actions :shipment="shipment" size="small" @update="onUpdate">
                  <template #button>
                    <a-button icon="ellipsis" @click="e => e.stopPropagation()" />
                  </template>
                </j-detail-actions>
              </template>
            </j-shipment>
          </a-list-item>
        </a-list>
      </div>
    </div>

    <!-- Filters -->

    <j-filters
      :show="filtering"
      @close="filtering = false" />

    <!-- Print -->

    <j-print
      :show="printing"
      @close="printing = false" />

    <!-- Export -->

    <j-export
      :show="exporting"
      @close="exporting = false" />

  </div>
</template>
<script>
import Vue from 'vue'
import lms from '@/api/lms'
import acl from '@/auth/acl'
import JFilters from './filters'
import JPeriod from './period'
import JExport from './export'
import JPrint from './print'
import JDetailActions from './detail/actions'
import JShipment from '@/views/shared/items/shipment'

export default {
  components: {
    JFilters,
    JPeriod,
    JExport,
    JPrint,
    JShipment,
    JDetailActions,
  },
  data: function () {
    return {
      acl,
      filtering: false,
      printing: false,
      exporting: false,
      query: null,
      selected: {},
    }
  },
  computed: {
    results() {
      return this.$store.getters['shipments/RESULTS']
    },
    filters() {
      return this.$store.getters['shipments/FILTERS']
    },
    pagination() {
      return this.$store.getters['shipments/PAGINATION']
    },
    selections() {
      return Object.values(this.selected).reduce((count, value) => count + Boolean(value), 0)
    },
    isFiltered() {
      return this.$store.getters['shipments/IS_FILTERED']
    },
  },
  watch: {
    filters: {
      deep: false,
      immediate: true,
      handler: 'fetch',
    },
    pagination: {
      deep: false,
      immediate: false,
      handler: 'fetch',
    },
  },
  methods: {
    fetch() {
      this.query = this.$store.getters['shipments/QUERY']
      const svcWithQuery = () => lms.shipment.search({ q: this.query.trim() })
      const svcWithFilters = () => lms.shipment.search(this.filters, this.pagination)
      const svc = this.query ? svcWithQuery : svcWithFilters
      return svc().then(response => this.$store.commit('shipments/SET_RESULTS', response.data))
    },
    onUpdate(shipment) {
      this.$store.commit('shipments/SET_RESULT', shipment)
    },
    onCheck({ checked, code }) {
      if (checked) {
        Vue.set(this.selected, code, true)
      } else {
        Vue.set(this.selected, code, false)
      }
    },
    onClear() {
      this.selected = {}
    },
    onPrint() {
      const code = Object.keys(this.selected).filter(k => this.selected[k] === true)
      const printer = this.$store.state.settings.printer
      lms.label.download({ code }, printer)
        .then(response => {
          window.open(response.data.url, '_blank')
        })
    },
    onSearch(text) {
      const filters = text ? { q: text.trim() } : this.filters
      lms.shipment.search(filters)
        .then(response => this.$store.commit('shipments/SET_RESULTS', response.data))
        .then(_ => this.$store.commit('shipments/SET_QUERY', text))
    },
    onCreate() {
      this.$router.push('/shipments/create')
    },
    onClearFilters() {
      this.$store.commit('shipments/CLEAR_FILTERS')
    },
    onChange(page) {
      window.scrollTo(0, 0)
      this.$store.commit('shipments/SET_PAGINATION', {
        page: page,
        size: this.pagination.size,
      })
    },
  },
}
</script>
<style lang="scss">
@import '@/components/mixins.scss';
table {
  border-collapse: collapse!important;
}
.lag-delayed {
 border-left: 5px solid $warning;
}
.lag-critical {
 border-left: 5px solid $danger;
}
</style>
